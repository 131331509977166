import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'

import './content-grid.scss'

const ContentGrid = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <div id={ props.blockClass } className={ `content-grid block-${ props.blockClass }`}>
            <Fade bottom cascade>
                <div className="content-grid__container">
                    <h3>{  props.title }</h3>
                    <div className="content-grid__content"
                        dangerouslySetInnerHTML={createMarkup()} />
                </div>
            </Fade>
        </div>
    )
}

ContentGrid.propTypes = {
    content: propTypes.string,
    title: propTypes.string,
    blockClass: propTypes.string
}

export default ContentGrid
