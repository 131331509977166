import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import MainTitle from '../components/MainTitle'
import Content from '../components/Content'
import ContentGrid from '../components/ContentGrid'

const OqueFazemos = ({ data: { directusServico: services } }) => (
    <Layout>
        <SEO title="O que fazemos" />

        <MainTitle
            content={ services.titulo }
            pageClass="pageServices" />

        <Content
            content={ services.introducao } />
        
        <ContentGrid
            title={ services.titulo_psicoterapia }
            content={ services.texto_psicoterapia }
            blockClass="psicoterapia" />
        
        <ContentGrid
            title={ services.titulo_organizacional }
            content={ services.texto_organizacional }
            blockClass="organizacional" />
        
        <ContentGrid
            title={ services.titulo_social }
            content={ services.texto_social }
            blockClass="social" />

    </Layout>
)

export const query = graphql`
  {
    directusServico {
      titulo
      introducao
      titulo_psicoterapia
      texto_psicoterapia
      titulo_organizacional
      texto_organizacional
      titulo_social
      texto_social
    }
  }
`

export default OqueFazemos
