import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'

import './content.scss'

const Content = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <div className="content">
            <div className="content__container">
                <Fade bottom cascade>
                    <div className="content__content"
                        dangerouslySetInnerHTML={createMarkup()} />
                </Fade>
            </div>
        </div>
    )
}

Content.propTypes = {
    content: propTypes.string
}

export default Content
